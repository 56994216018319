
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import GrantApp from "@/sg_copy/model/GrantApp";
import AppDropdownMenu from "@/sg_copy/components/AppDropdownMenu.vue";
import AppSubmissionExpandedCard from "@/sg_copy/components/AppSubmissionExpandedCard.vue";
import {tooltipMixin} from "@/sg_copy/mixins/TooltipMixin";
import AppStatus from "@/sg_copy/components/AppStatus.vue";
import {AppDropdownAction} from "@/sg_copy/model/AppDropdownAction";

@Component({
  mixins: [tooltipMixin],
  components: {
    AppStatus,
    AppDropdownMenu,
    AppSubmissionExpandedCard,
  },
})
export default class AppSubmissionCard extends Vue {
  @Prop() private app!: GrantApp;

  public showAppDetail(): void {
    this.emitAppDetail(this.app);
  }

  // noinspection JSUnusedLocalSymbols
  @Emit("app-detail")
  public emitAppDetail(app: GrantApp): void {
    // emits
  }

  @Emit("focused-app")
  public emitFocusedApp(appId: number): void {
    // emits
  }

  // noinspection JSUnusedLocalSymbols
  @Emit("dropdown-action")
  public dropDownAction(app: GrantApp, action: AppDropdownAction): void {
    // emits
  }

  get status(): string {
    return this.app.subsequentStatus.toString();
  }
}
