
import moment from "moment-timezone";
import {Component, Prop, Vue} from "vue-property-decorator";
import {
  FormDto,
  GrantAppDetailDto,
  ResponseDto,
  ResponseDtoStatusEnum,
} from "@/sg_copy/swagger-generated";
import AppStatus from "@/sg_copy/components/AppStatus.vue";

const DEFAULT_TIME_ZONE_ID = "Australia/Sydney";
@Component({
  name: "NestedForms",
  components: {AppStatus},
})
export default class NestedForms extends Vue {
  @Prop() private mainApp: GrantAppDetailDto;

  get appsByForm(): Map<number, GrantAppDetailDto[]> {
    const map = new Map<number, GrantAppDetailDto[]>();
    for (let form of this.mainApp.childForms) {
      map.set(form.id, []);
    }
    for (let app of this.mainApp.childApps) {
      const apps = map.get(app.roundFormId);
      if (apps) {
        apps.push(app);
      }
    }
    return map;
  }

  get baseUrl(): string {
    return `https://${this.mainApp.sitePublicUrl}/`;
  }

  hasNestedForms(app: GrantAppDetailDto): boolean {
    return app.childForms.length > 0;
  }

  hasSiblingForms(app: GrantAppDetailDto): boolean {
    return app.responses.length > 1;
  }

  siblingResponses(app: GrantAppDetailDto): ResponseDto[] {
    // There is no link in the raw data between the responses and actual forms,
    // so the main form will be assumed to be the one with the lowest response
    // Id, since none of the subsequent forms/responses can be created before
    // the first response is created.
    const respIds = app.responses.map(r => r.responseId);
    const firstResponseId = Math.min(...respIds);
    return app.responses.filter(r => r.responseId !== firstResponseId);
  }

  linkToStart(form: FormDto): string {
    return `${this.baseUrl}${form.id}/${this.mainApp.applicationId}/startlinked`;
  }

  linkToContinue(grantApp: GrantAppDetailDto): string {
    return this.linkResponse(grantApp.responses[0]);
  }

  linkResponse(resp: ResponseDto): string {
    return `${this.baseUrl}form/${resp.responseId}/continue`;
  }

  status(grantApp: GrantAppDetailDto): string {
    const status = grantApp.responses[0]
      ? grantApp.responses[0].status
      : ResponseDtoStatusEnum.NOT_STARTED;
    return status.toString();
  }

  responseStatus(resp: ResponseDto): string {
    return resp?.status || ResponseDtoStatusEnum.NOT_STARTED;
  }

  getSubmittedDate(grantApp: GrantAppDetailDto): string {
    if (grantApp.responses[0]) {
      return this.responseSubmittedDate(grantApp.responses[0]);
    }
    return "";
  }

  responseSubmittedDate(resp: ResponseDto): string {
    if (resp.submitDate) {
      const date = moment(resp.submitDate)
        .tz(DEFAULT_TIME_ZONE_ID)
        .format("DD MMM YYYY");
      return ` (${date})`;
    }
    return "";
  }

  titleId(app: GrantAppDetailDto): string {
    return "project-title-" + app.applicationId;
  }
}
