import {
  CurrencyDto,
  ResponseDto,
  ResponseDtoStatusEnum,
  TimeZoneDto,
  UserDto,
} from "@/sg_copy/swagger-generated";
import moment from "moment";
import "moment-timezone";

const DATE_FORMAT = "h:mma, DD MMM YYYY";
const DEFAULT_TIME_ZONE_ID = "Australia/Sydney";

export default class Response {
  private response!: ResponseDto;
  private user!: UserDto;

  constructor(response: ResponseDto, user: UserDto) {
    this.response = response;
    this.user = user;
  }

  public get isSubmitted(): boolean {
    return this.response.status === ResponseDtoStatusEnum.SUBMITTED;
  }

  public get statusDate(): string {
    if (this.isSubmitted) {
      return (
        "Submitted " +
        (this.response.submitDate
          ? this.formattedDate(this.response.submitDate)
          : "")
      );
    } else if (this.response.dueDate) {
      if (this.response.status === "INCOMPLETE") {
        return (
          "<b " +
          (this.overdue ? "style='color:lightgrey'" : "") +
          ">Due " +
          this.formattedDate(this.response.dueDate) +
          "</b>"
        );
      } else {
        return (
          "<b " +
          (this.dueInaWeek ? "style='color:rgb(200,0,0)'" : "") +
          ">Due " +
          this.formattedDate(this.response.dueDate) +
          "</b>"
        );
      }
    } else {
      return "<i style='color:lightgrey'>No due date</i>";
    }
  }

  public get timeZoneShortName(): string {
    const shortName: string = this.timeZone.shortName;
    if (this.isSubmitted) {
      return "(" + shortName + ")";
    } else if (this.response.dueDate) {
      if (this.response.status === "INCOMPLETE") {
        return (
          "<b " +
          (this.overdue ? "style='color:lightgrey'" : "") +
          ">(" +
          shortName +
          ")" +
          "</b>"
        );
      } else {
        return (
          "<b " +
          (this.dueInaWeek ? "style='color:rgb(200,0,0)'" : "") +
          ">(" +
          shortName +
          ")" +
          "</b>"
        );
      }
    } else {
      return "";
    }
  }

  public get timeZone(): TimeZoneDto {
    return this.response.timeZone;
  }

  public formattedDateWithTimeZone(date: string): string {
    return this.formattedDate(date) + "  " + this.response.timeZone;
  }

  public formattedDate(date: string): string {
    const dueDate = moment(date).tz(this.timeZoneId).format(DATE_FORMAT);
    const midnight = "12:00am";
    return dueDate.includes(midnight)
      ? "midnight, " +
          moment(date)
            .tz(this.timeZoneId)
            .subtract(1, "days")
            .format("DD MMM YYYY")
      : moment(date).tz(this.timeZoneId).format(DATE_FORMAT);
  }

  public get overdue(): boolean {
    return (
      this.response.dueDate &&
      moment(this.response.dueDate)
        .tz(this.timeZoneId)
        .isSameOrBefore(moment().tz(this.timeZoneId).subtract(1, "seconds"))
    );
  }

  public get dueInaWeek(): boolean {
    return (
      this.response.dueDate &&
      moment(this.response.dueDate)
        .tz(this.timeZoneId)
        .diff(moment().tz(this.timeZoneId), "days") < 7
    );
  }

  public get updatedBy(): string {
    return (
      "Last updated on " +
      moment(this.response.lastUpdateDate)
        .tz(this.timeZoneId)
        .format(DATE_FORMAT) +
      " by " +
      this.response.lastUpdateBy +
      (this.response.lastUpdateById === this.user.id ? " (me)" : "")
    );
  }

  public get timeZoneId(): string {
    return this.timeZone.id ? this.timeZone.id : DEFAULT_TIME_ZONE_ID;
  }

  public get responseLink(): string {
    return (
      "https://" +
      this.response.sitePublicUrl +
      "/form/" +
      this.response.responseId +
      "/continue"
    );
  }

  public get pdfLink(): string {
    return "/d/strigiform/" + this.response.responseId + ".pdf";
  }

  public get formName(): string {
    return this.response.formName;
  }

  public get status(): ResponseDtoStatusEnum {
    return this.response.status;
  }

  public get getTotalAmountRequested(): CurrencyDto {
    return this.response.totalAmountRequested;
  }

  public get responseId(): number {
    return this.response.responseId;
  }
}
